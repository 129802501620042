import axios from 'axios';
import { LAMDA_APICALL, GET_APP_CONFIG } from './apiurl';

export async function axiosCall(method, url, data, callback) {
    return axios({

        method: method,
        url: url,
        data: data

    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        if (error.response && error.response.status === 401) {
            console.log('unauthorized, logging out ...');
            localStorage.clear();
            window.location = '/signin'
        }
        callback(error.response ? error.response.data : error.data);
        console.log(error, error.response);
    });



}
export async function apiCall(method, url, data) {
    return new Promise(async (resolve, reject) => {
        axios({
            method: method,
            url: url,
            data: data

        }).then((resp) => {
            console.log(resp);
            resolve(resp.data);

        })
            .catch((error) => {
                if (error.data && error.data.status === 401) {
                    console.log('unauthorized, logging out ...');
                    localStorage.clear();
                    window.location = '/signin'
                }
                reject(error.data);
                console.log(error);
            });
    })

}

export function getMenu() {
    const menu = []
    const partner_id = JSON.parse(localStorage.getItem("partner_id")) || JSON.parse(localStorage.getItem("partner_idd"))
    console.log("partner id in menu", partner_id)
    const user = JSON.parse(localStorage.getItem("user"))
    const roleKey = user && user.UserRoleMapping && user.UserRoleMapping[0] &&
        user.UserRoleMapping[0].Role && user.UserRoleMapping[0].Role.roleKey ? user.UserRoleMapping[0].Role.roleKey : null
    console.log('roleKey.', roleKey);
    switch (roleKey) {
        case "ADMIN":
            menu.push({ label: 'Dashboard', link: '/adminDashboard/' })
            menu.push({ label: 'User Management', link: '/admin/adminUserManagement' })
            // menu.push({ label: 'Subscribed Users', link: '/admin/subscribedUsers' })
            menu.push({ label: 'Account Management', hasSubNav: true, sub: [{ name: "Confirmed", link: '/admin/confirmedUsers' }, { name: "Unconfirmed", link: '/admin/unConfirmedusers' }, { link: "/admin/demo-user", name: "Demo Users" }] })
            menu.push({ label: 'Kit ID Service', link: '/admin/kit-ids' })
            // menu.push({ label: 'Content', link: '/content', hasSubNav: false }) 
            break;
        case "ADMIN_ACCOUNT_MANAGER":
            menu.push({ label: 'Dashboard', link: '/adminDashboard/' })
            menu.push({ label: 'Account Management', hasSubNav: true, sub: [{ name: "Confirmed", link: '/admin/confirmedUsers' }, { name: "Unconfirmed", link: '/admin/unConfirmedusers' }, { link: "/admin/demo-user", name: "Demo Users" }] })
            // menu.push({ label: 'Content', link: '/content', hasSubNav: false }) 
            break;
        case "ADMIN_ACCOUNT_VIEW":
            menu.push({ label: 'Account Management', hasSubNav: true, sub: [{ name: "Confirmed", link: '/admin/confirmedUsers' }, { name: "Unconfirmed", link: '/admin/unConfirmedusers' }] })
            // menu.push({ label: 'Content', link: '/content', hasSubNav: false }) 
            break;
        case "SUPER_ADMIN":
            const appsAllowed = JSON.parse(localStorage.getItem('app_management_partners'));
            const isAppConfigAllowed = appsAllowed.includes(partner_id)
            console.log({appsAllowed, isAppConfigAllowed})
            menu.push({ label: 'Dashboard', link: '/adminDashboard/' })
            menu.push({ label: 'Kit ID Service', link: '/admin/kit-ids' })
            menu.push({ label: 'User Management', link: '/admin/adminUserManagement' })
            // menu.push({ label: 'Subscribed Users', link: '/admin/subscribedUsers' })
            menu.push({ label: 'Account Management', hasSubNav: true, sub: [{ name: "Confirmed", link: '/admin/confirmedUsers' }, { name: "Unconfirmed", link: '/admin/unConfirmedusers' }, { link: "/admin/demo-user", name: "Demo Users" }] })
            // isAppConfigAllowed && menu.push({ label: 'App Management', hasSubNav: true, sub: [{ name: "Dashboard Management", link: '/admin/app/dashboard' }, { name: "Home Page Management", link: '/admin/app/home' }] })
            isAppConfigAllowed && menu.push({ label: 'App Management', hasSubNav: true, sub: [{ name: "Dashboard Management", link: '/admin/app/dashboard' }] })
            // menu.push({ label: 'Content', link: '/content', hasSubNav: false }) 
            break;
        case "DASHBOARD_USER":
            menu.push({ label: 'Dashboard', link: '/adminDashboard/' })
            break;
        case "ADMIN_DASHBOARD_VIEW":
            menu.push({ label: 'Dashboard', link: '/adminDashboard/' })
            break;
        default: break
    }
    return menu
}