// history
export const History = require("history").createBrowserHistory();

// view
export { View } from './view/view';
export { Modal } from './modal/modal';
export { Notification } from './notification/notification';

// context
export { ViewContext } from './view/view';
export { AuthContext } from '../app/auth';

// layout
export { AppLayout } from './layout/app/app';
export { MasterLayout } from './layout/master/master';
export { AuthLayout } from './layout/auth/auth';
export { ExternalLayout } from './layout/external/external';
export { SetupLayout } from './layout/setup/setup';
export { HomeLayout } from './layout/home/home';
export { Grid } from './grid/grid';

// nav
// export { AppNav } from './nav/app/app';
export { SubNav } from './nav/sub/sub';
export { AccountNav } from './nav/sub/account';
export { Breadcrumbs } from './nav/breadcrumbs/breadcrumbs';
export { HoverNav } from './nav/hover/hover';

// progress
export { ProgressBar } from './progress/bar/bar';
export { ProgressSteps } from './progress/steps/steps';

// stats
export { Stat } from './stat/stat';
export { Chart } from './chart/chart';
export { SuperAdminCharts } from './chart/superAdminCharts';
export { AdminCharts } from './chart/adminCharts';
export { Selectcard } from './selectcard/selectcard';
export { DashboardBox } from './dashboard-box/dashboard-box';
export { AccountBox } from './dashboard-box/accounts-box';
export { DateFilterBox } from './dashboard-box/date-filter-box';
export { PartnerBox } from './dashboard-box/partner-box';
// form
export { Form } from './form/form';
export { PaymentForm } from './form/form';
export { Switch } from './form/switch/switch';
export { Search } from './search/search';

// table
export { Table } from './table/table';
export { DeleteTableRow } from './table/table';
export { UpdateTableRow } from './table/table';
export { Actions } from './table/actions/actions';

export { Badge } from './badge/badge';
export { Icon } from './icon/icon'

// button
export { Button } from './button/button';
export { LoadingButton } from './button/button-loading';
export { IconButton } from './button/button-icon';

// list
export { List } from './list/list';
export { CheckList } from './list/checklist/checklist';

// message
export { Message } from './message/message';
export { BlankSlateMessage } from './message/blankslate/blankslate';

// homepage
export { HomeNav } from './nav/home/home';
export { Testimonial } from './testimonial/testimonial';
export { FeatureList } from './features/features';
export { Steps } from './steps/steps';
export { PricePlans } from './pricing/plans';
export { HomeFooter } from './footer/home/home';

// the rest
export { Link } from './link/link';
export { Social } from './social/social';
export { Loader } from './loader/loader';
export { TabView } from './tabview/tabview';
export { Header } from './header/header';
export { Card } from './card/card';
export { Logo } from './logo/logo';
export { Row } from './layout/row';
export { Image } from './image/image';
export { ViewRawDataModal } from './modal/viewRawDataModal';
export { ViewHistoryModal } from './modal/viewHistoryModal';
export { ViewKitDetailsModal } from './modal/viewKitDetailsModal';
export { ViewQuestionsModal } from './modal/viewQuestionsModal';

