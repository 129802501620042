/***
*
*   CHART
*   Responsive chart that supports multiple datasets and chart types.
*
*   PROPS
*   type: line/bar/pie/donut/sparkline
*   legend: true/false to toggle the legend
*   data: API endpoint OR object for the chart data
*   object: object key to use in server response object (optional)
*   color: red/blue/purple/green (pass array for multiple datasets)
*
**********/

import React from 'react';
import Moment from 'moment';
import { axiosCall } from '../../helpers';
import { LAMDA_APICALL, GET_GRAPH_DATA } from '../../apiurl';

import { ViewContext, Loader, Icon, Grid } from 'components/lib';
import { LineChart } from './line';
import './chart.scss';
import Select from 'react-select';
import SelectMulti from "react-dropdown-select";

const Colors = require('./colors.json');
const Options = require('./options.json');
const Methods = require('./methods');

export class SuperAdminCharts extends React.PureComponent {

    constructor(props) {

        super(props);

        // config the options
        this.colors = JSON.parse(JSON.stringify(Colors));
        this.options = JSON.parse(JSON.stringify(Options));
        this.options.scales.yAxes[0].ticks.callback = Methods.roundTicks;
        this.options.tooltips.callbacks.label = Methods.formatTooltipLabel;

        this.charts = {
            line: LineChart
        }
        this.state = {
            loading: false,
            data: null,
            color: null,
            selectedOptionPartner: { value: "", label: "All" },
            selectedOptionWeek: { value: 'Last7Days', label: 'Last 7 days' },
            accountsOptions: [
                { value: 'DNA_LITE', label: 'DNA LITE' },
                { value: 'DNA_ONLY', label: 'DNA ONLY' },
                { value: 'EPI_ONLY', label: 'EPI ONLY' },
                { value: 'DNA_EPI', label: 'DNA EPI' },
                { value: 'DNA_EPI_SUB', label: 'DNA EPI SUB' },
                { value: 'EPI_ONLY_SUB', label: 'EPI ONLY SUB' },
                { value: 'PENDING', label: 'PENDING' }
            ],
            multiValue: []
        }
        this.finalArray = [];
    }

    componentDidMount() {

        // get the data from the server
        if (typeof this.props.data === 'string') {
            this.setState({ loading: true });
            this.getData();
        }
    }


    async getData() { 
        console.log(' this.state.selectedOptionWeek.value', this.state.selectedOptionWeek.value,);
        let resultDate
        if ( this.state.selectedOptionWeek.value === 'Last7Days') {
            resultDate = {today: Moment().format('YYYY-MM-DD'),lastDay :Moment().subtract(7, 'days').format('YYYY-MM-DD')}// await this.getDate(7);
        } else if ( this.state.selectedOptionWeek.value === '24Hrs') {
            resultDate = {today: Moment().format('YYYY-MM-DD'),lastDay :Moment().subtract(1, 'days').format('YYYY-MM-DD')}// await this.getDate(1);
        } else if ( this.state.selectedOptionWeek.value === 'Last30Days') {
            resultDate = {today: Moment().format('YYYY-MM-DD'),lastDay :Moment().subtract(30, 'days').format('YYYY-MM-DD')} //await this.getDate(30);
        } else if ( this.state.selectedOptionWeek.value === 'Last90Days') {
            resultDate = {today: Moment().format('YYYY-MM-DD'),lastDay :Moment().subtract(90, 'days').format('YYYY-MM-DD')} //await this.getDate(180);
        } else if ( this.state.selectedOptionWeek.value === 'Last180Days') {
            resultDate = {today: Moment().format('YYYY-MM-DD'),lastDay :Moment().subtract(180, 'days').format('YYYY-MM-DD')} //await this.getDate(180);
        } else {
            resultDate = {today: Moment().format('YYYY-MM-DD'),lastDay :Moment().subtract(7, 'days').format('YYYY-MM-DD')} //await this.getDate(7);
        }

        try {
            await axiosCall('post', LAMDA_APICALL, {
                url: GET_GRAPH_DATA,
                method: "POST",
                data: {
                    "partner_id": this.state.selectedOptionPartner.value,
                    "time": this.state.selectedOptionWeek.value,
                    "today": resultDate.today,
                    "lastDay":resultDate.  lastDay
                }
            }, (response) => {
                let data;

                if (response) {
                    data = response.data;
                    data.type = this.props.type;
                    console.log("asdsdadas", data.datasets);

                    console.log("this.finalArray", this.finalArray);
                    if (this.finalArray.length > 0) {
                        var labelll = data.datasets.filter((final) => {
                            return this.finalArray.indexOf(final.key) !== -1;
                        });
                        data.datasets = labelll;
                    }
                    console.log("except", data);
                    this.setState({

                        data: data,
                        loading: false,

                    }, () => {

                        this.setChartColors();

                    });
                }
            });

        }
        catch (err) {

            this.context.handleError(err);

        }
    }
    getDate(days) {
        let date = new Date();
        let today = date.toISOString();
        date.setDate(date.getDate() - days);
        let lastDay = date.toISOString();
        console.log({ today: today, lastDay: lastDay });
        return { today: today, lastDay: lastDay }
      }

    setChartColors() {

        // set the color
        let colors = [];

        // override with user defined color
        if (this.props.color) {
            if (Array.isArray(this.props.color)) {
                if (this.props.color.length) {
                    for (let i = 0; i < this.props.color.length; i++) {
                        colors[i] = this.colors[this.props.color[i]];
                    }
                }
            }
            else {
                colors[0] = this.colors[this.props.color];
            }
        }

        // set default color
        for (let color in this.colors)
            colors.push(this.colors[color]);

        if (this.state.data) {
            if (this.state.data.datasets) {
                if (this.state.data.datasets.length) {

                    const type = this.props.type;
                    const data = this.state.data;

                    for (let i = 0; i < data.datasets.length; i++) {

                        data.datasets[i].borderColor = colors[i].borderColor;
                        data.datasets[i].backgroundColor = colors[i].backgroundColor[i];

                        if (type === "line") {
                            data.datasets[i].pointBackgroundColor = colors[i].pointBackgroundColor;
                            data.datasets[i].backgroundColor = colors[i].transparentColor;
                            data.datasets[i].pointRadius = colors[i].pointRadius;
                            data.datasets[i].pointHoverRadius = colors[i].pointHoverRadius;
                            data.datasets[i].pointBorderWidth = colors[i].pointBorderWidth;
                            data.datasets[i].pointBackgroundColor = colors[i].pointBackgroundColor;
                            data.datasets[i].pointHoverBackgroundColor = colors[i].pointHoverBackgroundColor;
                            data.datasets[i].pointHoverBorderColor = colors[i].pointHoverBorderColor;
                        }
                    }
                }
            }
        }

        this.setState({ color: colors });

    }
    partnerChange = async (e) => {
        await this.setState({ selectedOptionPartner: e });
        await this.getData();
    };

    weekChange = async (e) => {
        await this.setState({ selectedOptionWeek: e });
        await this.getData();
    }
    selectLines = async (e) => {
        await this.setState({ multiValue: e });
        this.finalArray= [];
        await this.state.multiValue.forEach(accountType => {
            this.finalArray.push(accountType.value);
        });
        await this.getData();
    }
    render() {
        const { selectedOptionPartner, selectedOptionWeek } = this.state;
        // set the chart colors and type
        const chartType = this.props.type ? this.props.type : 'line';
        const Chart = this.charts[chartType];

        if (this.state.loading)
            return (<div className="chartWrapper"><Loader /></div>);

        if (this.state.data) {

            // no chart data – render blank slate
            if (this.state.data.datasets[0].data.length === 0) {
                return (
                    <div className="chartWrapper">
                        <div className="blankslate">
                            <Icon image='chartup' />
                            No data to show
                        </div>
                    </div>
                );
            }

            // render the chart
            let legend = [];

            if (this.state.color) {

                // create the legend
                if (this.props.legend) {

                    if (this.props.type === 'line' || this.props.type === 'bar') {
                        this.state.data.datasets.map((data, index) => {

                            legend.push(
                                <li key={data.label}>
                                    <span style={{
                                        backgroundColor: this.state.data.datasets[index].borderColor
                                    }}>
                                    </span>
                                    {data.label}
                                </li>
                            );

                            return false;

                        });
                    }
                }

                // render the chart
                return (

                    <div className="chartWrapper">
                        {this.props.filter &&
                            <Grid cols='4'>
                                <Select
                                    options={this.props.partnerOptions}
                                    value={selectedOptionPartner}
                                    onChange={this.partnerChange}
                                />
                                <Select
                                    options={this.props.filterOptions}
                                    value={selectedOptionWeek}
                                    onChange={this.weekChange}
                                />
                                <SelectMulti
                                    multi
                                    options={this.state.accountsOptions}
                                    values={[]}
                                    onChange={this.selectLines}
                                    placeholder="Type: All"
                                    className="multiSelect"
                                    color="#2684FF"
                                />
                            </Grid>
                        }
                        <div id="chart-tooltip"></div>

                        {this.props.legend &&
                            <ul className='legend'>{legend}</ul>
                        }
                        <div className='chart'>
                            <Chart data={this.state.data} options={this.options} />
                        </div>

                    </div>

                );
            }
            else {

                return false;

            }
        }
        else {

            return false;

        }
    }
}

SuperAdminCharts.contextType = ViewContext;
