// Express Api

export const LOGIN                  = '/api/user/auth'
export const USERLIST_API           = '/api/user/list'
export const ADMIN_ROLES            = '/api/role/adminRole'
export const GET_USER               = '/api/user/'
export const GET_DEMO_USERS         = '/api/user/firebaseUserList'
export const EXPORT_USER            = '/api/user/exportUser'
export const UPDATE_DEMO_USER       = '/api/user/userUpdation'
export const SUPER_ROLES            = '/api/role/super'
export const GET_PARTNER            = '/api/partner/'
export const LAMDA_APICALL          = '/api/call/'
export const ACCOUT_VALIDATE_TOKEN  = '/api/token/'
export const RESET_PASSWORD         = '/api/token/changePassword'
export const FORGOT_PASSWORD        = '/api/user/forgotPassword'
export const EMAIL_RESENT           = '/api/token/resend'
export const KIT_UPLOAD             = '/api/kits/upload'
export const GET_PARTNER_VIA_EMAIL  = '/api/user/partners/'

// LAMDA URL
export const GET_ACCOUNT_COUNT      = 'V2/user/get-accounts-count'
export const UPDATE_USER            = 'V2/user/update'
export const GET_GRAPH_DATA         = 'user/user/userCountByDate'
export const GET_CONFIRMED_USERS    = 'user/user/listUser'
export const GET_ONE_USER           = 'user/user/getUser'
export const UPDATE_ACCESS          = 'user/user/updateAccessControl'
export const ADD_KIT_SINGLE         = 'kit-logestics/dna/kit/add'
export const BULK_KIT_UPLOAD        = 'kit-logestics2/kit/saveBulkKits'   
export const BULK_CSV_IMPORT        = 'kit-logestics2/kit/importBulkKitStatus'
export const BULK_CSV_UPLOAD        = 'kit-logestics2/kit/uploadBulkKitStatus'
export const DOB_CSV_EXPORT        = 'kit-logestics2/kit/exportDobReport'
export const EXPORT_PDF             = 'practitioner-V2/v1/dummypdf/multiple'
export const MUHDO_ADD_KIT          = 'kit-logestics/dna/kitlogistics/kitregistrationandactivation'
export const TBG_ADD_KIT            = 'external/tbg/user/kitRegistration'
export const BIO_ADD_KIT            = 'external/bioSynergy/user/kitRegistration'
export const LONG_ADD_KIT           = 'external-v2/longevity/kitRegistration'
export const NUROKOR_ADD_KIT        = 'external-v2/nurokor/kitRegistration'
export const FH_ADD_KIT             = 'external-v2/formulaHealth/kitRegistration'
export const TN_ADD_KIT             = 'external-v2/transformNow/kitRegistration'
export const PN_ADD_KIT             = 'external-v2/patientNavigator/kitRegistration'
export const BODYOLOGY_ADD_KIT      = 'external-v2/bodyology/kitRegistration'
export const VALA_ADD_KIT           = 'external-v2/vala/kitRegistration'
export const DNA_BEYOND_ADD_KIT     = 'external-v2/dnaBeyond/kitRegistration'

export const KIT_VALIDATION         = 'kit-logestics/dna/kitlogistics/getKitByIdExceptSelf'
export const GET_KIT_ID             = 'kit-logestics2/kit/listAll'
export const RELEASE_KIT            = 'kit-logestics2/dna/kitlogistics/freeKitIdByUserId'
export const DELETE_USER            = 'auth/auth/deleteUser'
export const GET_COUNTRIES          = 'countries/dna/countries/getall'
export const GET_RAWDATA            = 'kit-raw-data/dna/user/rawdataByMode'
export const EXPORT_USERS           = 'user/user/exportUsers'
export const KIT_DELETE             = 'kit-logestics/dna/kit/softdeletevalidkitid'
export const RESEND_CONFIRM_EMAIL   = 'auth/auth/resendConfirmationMailToUser' 
export const KIT_TRACKING_HISTORY   = 'kit-logestics2/kit-tracking/history' 
export const KIT_TRACKING_DETAILS   = 'kit-logestics2/updateTrackingDetails'
export const EXPORT_REPORT          = 'kit-raw-data/dna/user/ExportRawData'
export const ADD_KIT_TO_QUEUE       = 'kit-logestics2/addToKitTrackingQueue'
export const ADD_AS_PARENT          = 'practitioner-V2/v1/kittracking/interchange'
export const Subscribed_Users       = 'user/subscriber/listUser'
export const Download_Csv           =  'user/subscriber/jsontocsv'
export const GET_DASHBOARD_ITEMS    =  'app/dashboard/admin-get-items'
export const UPDATE_DASHBOARD_ITEMS =  'app/dashboard/update-items'
export const GET_APP_CONFIG         =  'app/dashboard/get-partners'

//DNA12
export const GET_DNA12_MODES        =   'dna12/mode/get-all'
export const ADD_MODE               =   'dna12/mode/create'
export const ADD_DNA12_QUESTION     =   'dna12/question/create'
export const EDIT_DNA12_QUESTION    =   'dna12/question/update'

// MARVEL KIT UPLOAD
export const KIT_SERVICE_UPLOAD     =   'kit-logestics2/kit/uploadServiceType'
export const GET_KIT_SERVICE        =   'kit-logestics2/kit/getKitService'