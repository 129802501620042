/***
*
*   VIEW
*   The view houses global components that are common to all views
*   (notification, modal), handles errors and renders the layout.
*
*   Read the full documentation: https://docs.usegravity.app/react/views
*
**********/

import React from 'react';

import {
  AppLayout, AuthLayout, ExternalLayout, HomeLayout,
  MasterLayout, SetupLayout, Modal, ViewRawDataModal, ViewHistoryModal,
  Notification, Loader, ViewKitDetailsModal, ViewQuestionsModal
} from "../lib";

import './scss/normalize.scss';
import './scss/view.scss';
import './scss/typography.scss';

export const ViewContext = React.createContext();

export class View extends React.Component {

  constructor() {

    super();

    this.layouts = {

      app: AppLayout,
      auth: AuthLayout,
      external: ExternalLayout,
      master: MasterLayout,
      setup: SetupLayout,
      home: HomeLayout

    }

    this.state = {

      loading: false,

      notification: {
        visible: "hide",
        autoclose: true,
      },

      modal: {
        visible: 'hide',
      },
      rawDataModal: {
        visible: 'hide',
      },
      historyModal: {
        visible: 'hide',
      },
      kitDetailsModal: {
        visible: 'hide',
      },
      questionModal: {
        visible: 'hide'
      }
    }

    this.showNotification = this.showNotification.bind(this);
    this.hideNotification = this.hideNotification.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.startLoading = this.startLoading.bind(this);
    this.finishLoading = this.finishLoading.bind(this);
    this.showRawDataModal = this.showRawDataModal.bind(this);
    this.hideRawDataModal = this.hideRawDataModal.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
    this.showTrackingHistoryModal = this.showTrackingHistoryModal.bind(this);
    this.hideTrackingHistoryModal = this.hideTrackingHistoryModal.bind(this);
    this.showKitDetailsModal = this.showKitDetailsModal.bind(this);
    this.hideKitDetailsModal = this.hideKitDetailsModal.bind(this);
    this.showQuestionsModal = this.showQuestionsModal.bind(this);
    this.hideQuestionsModal = this.hideQuestionsModal.bind(this);
  }

  componentDidMount() {

    // show notification?
    const n = JSON.parse(localStorage.getItem('notification'));

    if (n) {

      this.showNotification(n.text, n.type, n.autoclose);
      localStorage.removeItem('notification');

    }
    // let state = Object.assign({}, this.state);
    // this.setState(state, function () {

    //   state.rawDataModal.visible = 'animateIn';
    //   setTimeout(() => this.setState(state), 5);

    // });
  }

  showNotification(text, type, autoclose = true) {
    this.setState({
      notification: {

        text: text,
        type: type,
        visible: "show",
        autoclose: autoclose

      }
    });

    if (autoclose)
      setTimeout(this.hideNotification, 3000);

  }

  hideNotification() {

    this.setState({
      notification: {

        text: '',
        type: '',
        visible: '',
        autoclose: true

      }
    });
  }
  showRawDataModal(content, callback) {
    console.log(" showRawDataModal", content);
    let state = Object.assign({}, this.state);

    if (content) {

      for (let key in content)
        state.rawDataModal[key] = content[key];

      state.rawDataModal.visible = 'show'
      state.rawDataModal.callback = callback;

    }
    console.log('state', state)

    this.setState(state, function () {

      state.rawDataModal.visible = 'animateIn';
      setTimeout(() => this.setState(state), 5);

    });
  }

  hideRawDataModal(cancel) {
    console.log("hide RawDataModal");
    let state = Object.assign({}, this.state);
    state.rawDataModal.visible = 'animateOut';
    this.setState(state);

    // callback?
    if (!cancel && this.state.rawDataModal.callback) {
      this.state.rawDataModal.callback(this.state.rawDataModal.form);
    }

    // reset the rawDataModal


    setTimeout(() => {
      if (this.state.rawDataModal.form) {
        console.log("hideRawDataModal", this.state.rawDataModal)
        var valueState = this.state.rawDataModal.form
        Object.keys(valueState).forEach(val => {
          valueState[val].value = ''
        })
        this.setState({
          rawDataModal: {
            form: valueState
          }
        });
      }

      this.setState({
        rawDataModal: {
          kitId: null,
          title: null,
          visible: "hide",

        }
      })
    }, 150);
  }

  showKitDetailsModal(content, callback) {
    console.log(" showKitDetailsModal", content);
    let state = Object.assign({}, this.state);

    if (content) {

      for (let key in content)
        state.kitDetailsModal[key] = content[key];

      state.kitDetailsModal.visible = 'show'
      state.kitDetailsModal.callback = callback;

    }
    console.log('state', state)

    this.setState(state, function () {

      state.kitDetailsModal.visible = 'animateIn';
      setTimeout(() => this.setState(state), 5);

    });
  }

  hideKitDetailsModal(cancel) {
    console.log("hide KitDetailsModal");
    let state = Object.assign({}, this.state);
    state.kitDetailsModal.visible = 'animateOut';
    this.setState(state);

    // callback?
    if (!cancel && this.state.kitDetailsModal.callback) {
      this.state.kitDetailsModal.callback(this.state.kitDetailsModal.form);
    }

    // reset the kitdetailsmodal


    setTimeout(() => {
      if (this.state.kitDetailsModal.form) {
        console.log("hideKitDetailsModal", this.state.kitDetailsModal)
        var valueState = this.state.kitDetailsModal.form
        Object.keys(valueState).forEach(val => {
          valueState[val].value = ''
        })
        this.setState({
          kitDetailsModal: {
            form: valueState
          }
        });
      }

      this.setState({
        kitDetailsModal: {
          title: null,
          data: null,
          visible: "hide",

        }
      })
    }, 150);
  }

  showTrackingHistoryModal(content, callback) {
    console.log(" showTrackingHistoryModal", content);
    let state = Object.assign({}, this.state);

    if (content) {

      for (let key in content)
        state.historyModal[key] = content[key];

      state.historyModal.visible = 'show'
      state.historyModal.callback = callback;

    }
    console.log('state', state)

    this.setState(state, function () {

      state.historyModal.visible = 'animateIn';
      setTimeout(() => this.setState(state), 5);

    });
  }


  hideTrackingHistoryModal(cancel) {
    console.log("hide TrackingHistoryModal");
    let state = Object.assign({}, this.state);
    state.historyModal.visible = 'animateOut';
    this.setState(state);

    // callback?
    if (!cancel && this.state.historyModal.callback) {
      this.state.historyModal.callback(this.state.historyModal.form);
    }

    // reset the historyModal


    setTimeout(() => {
      if (this.state.historyModal.form) {
        console.log("hideTrackingHistoryModal", this.state.historyModal)
        var valueState = this.state.historyModal.form
        Object.keys(valueState).forEach(val => {
          valueState[val].value = ''
        })
        this.setState({
          historyModal: {
            form: valueState
          }
        });
      }

      this.setState({
        historyModal: {
          kitId: null,
          title: null,
          data: null,
          visible: "hide",

        }
      })
    }, 150);
  }

  showQuestionsModal(content, callback) {
    console.log("showQuestionsModal", content);
    let state = Object.assign({}, this.state);

    if (content) {

      for (let key in content)
        state.questionModal[key] = content[key];

      state.questionModal.visible = 'show'
      state.questionModal.callback = callback;

    }
    console.log('state', state)

    this.setState(state, function () {

      state.questionModal.visible = 'animateIn';
      setTimeout(() => this.setState(state), 5);

    });
  }
  hideQuestionsModal(cancel) {
    console.log("hide hideQuestionsModal");
    let state = Object.assign({}, this.state);
    state.questionModal.visible = 'animateOut';
    this.setState(state);

    // callback?
    if (!cancel && this.state.questionModal.callback) {
      this.state.questionModal.callback(this.state.questionModal.form);
    }

    // reset the questionModal


    setTimeout(() => {
      if (this.state.questionModal.form) {
        console.log("hideQuestionsModal", this.state.questionModal)
        var valueState = this.state.questionModal.form
        Object.keys(valueState).forEach(val => {
          valueState[val].value = ''
        })
        this.setState({
          questionModal: {
            form: valueState
          }
        });
      }

      this.setState({
        questionModal: {
          kitId: null,
          title: null,
          data: null,
          visible: "hide",

        }
      })
    }, 150);
  }
  showModal(content, callback) {
    let state = Object.assign({}, this.state);

    if (content) {

      for (let key in content)
        state.modal[key] = content[key];

      state.modal.visible = 'show'
      state.modal.callback = callback;

    }

    this.setState(state, function () {

      state.modal.visible = 'animateIn';
      setTimeout(() => this.setState(state), 5);

    });
  }

  hideModal(cancel) {
    console.log("amalallslalls");
    let state = Object.assign({}, this.state);
    state.modal.visible = 'animateOut';
    this.setState(state);

    // callback?
    if (!cancel && this.state.modal.callback) {
      this.state.modal.callback(this.state.modal.form);
    }

    // reset the modal


    setTimeout(() => {
      if (this.state.modal.form) {
        console.log("asdfff", this.state.modal)
        var valueState = this.state.modal.form
        Object.keys(valueState).forEach(val => {
          valueState[val].value = ''
        })
        this.setState({
          modal: {
            form: valueState
          }
        });
      }

      this.setState({
        modal: {
          lambda: null,
          title: null,
          text: null,
          buttonText: null,
          url: null,
          method: null,
          lambda_method: null,
          visible: "hide",

        }
      })
    }, 150);
  }

  startLoading() {

    this.setState({ loading: true });

  }

  finishLoading() {

    this.setState({ loading: false });

  }
  getErrorMessage(err) {
    let message = "Something went wrong"
    try {
      if (err.data) {
        if (err.data.errors) {
          // validation errors
          message = err.data.errors

          var msArray = err.data.errors.reduce((a, b) => {
            a.push(Object.values(b));
            return a
          }, []);
          message = msArray.join(", ")
        } else if (err.data.message.message) {
          message = err.data.message.message
        } else if (err.data.message) {
          message = err.data.message
        }
      }
    } catch (e) {

    }
    return message
  }
  handleError(err) {

    let message = 'There was a glitch in the matrix – please try again';
    console.log(err, 'handleError')
    if (err) {

      message = err.toString();

      if (err.response) {

        if (err.response.status === 401) {

          History.push('/signin');

        }



        if (err.response.data) {
          if (!err.response.data.status && err.response.data.message.message) {
            message = err.response.data.message.message;
          }
          else if (err.response.data.message) {

            message = ""
            if (err.response.data.errors && err.response.data.errors.length > 0) {
              err.response.data.errors.forEach(key => {
                console.log(Object.values(key)[0]);
                message += Object.values(key)[0] + ", "
              });

              // for (let [key, value] of err.response.data.errors) {
              //   console.log(`${key}: ${value}`);
              // }

            } else {
              message = err.response.data.message
            }
            console.log(message);

          }

          if (err.response.data.status) {
            switch (err.response.data.status) {

              case 404:
                History.push('/notfound');
                break;

              default:
                History.push('/notfound');
                break;

            }
          }
        }
      } else if (err.data) {
        message = this.getErrorMessage(err);
      }
    }

    this.showNotification(message, 'error', true);

  }



  render() {
    const language = this.props.language;
    let Layout = AppLayout;
    document.title = this.props.title;

    if (this.props.layout)
      Layout = this.layouts[this.props.layout];

    const viewContext = {

      notification: this.state.notification,
      showNotification: this.showNotification,
      hideNotification: this.hideNotification,
      modal: this.state.modal,
      rawDataModal: this.state.rawDataModal,
      kitDetailsModal: this.state.kitDetailsModal,
      historyModal: this.state.historyModal,
      questionModal: this.state.questionModal,
      showModal: this.showModal,
      hideModal: this.hideModal,
      handleError: this.handleError,
      startLoading: this.startLoading,
      finishLoading: this.finishLoading,
      showRawDataModal: this.showRawDataModal,
      hideRawDataModal: this.hideRawDataModal,
      showTrackingHistoryModal: this.showTrackingHistoryModal,
      hideTrackingHistoryModal: this.hideTrackingHistoryModal,
      showKitDetailsModal: this.showKitDetailsModal,
      hideKitDetailsModal: this.hideKitDetailsModal,
      showQuestionsModal: this.showQuestionsModal,
      hideQuestionsModal: this.hideQuestionsModal,
      getErrorMessage: this.getErrorMessage,
    }

    return (

      <ViewContext.Provider value={viewContext}>

        <Notification />

        <ViewRawDataModal
          kitId={this.state.rawDataModal.kitId}
          title={this.state.rawDataModal.title}>
        </ViewRawDataModal>

        <ViewHistoryModal
          kitId={this.state.historyModal.kitId}
          title={this.state.historyModal.title}
          data={this.state.historyModal.data}
          update={this.state.historyModal.update}
        >
        </ViewHistoryModal>

        <ViewQuestionsModal
          title={this.state.questionModal.title}
          mode={this.state.questionModal.mode}
          questions={this.state.questionModal.questions}
          addQuestion={this.state.questionModal.addQuestion}
          updateQuestion={this.state.questionModal.updateQuestion}
        >
        </ViewQuestionsModal>

        <ViewKitDetailsModal
          title={this.state.kitDetailsModal.title}
          data={this.state.kitDetailsModal.data}
        >
        </ViewKitDetailsModal>

        <Modal
          title={this.state.modal.title}
          form={this.state.modal.form}
          lamda={this.state.modal.lamda}
          uploadCSV={this.state.modal.uploadCSV}
          exportCSV={this.state.modal.exportCSV}
          uploadBulk={this.state.modal.uploadBulk}
          uploadKitService={this.state.modal.uploadKitService}
          dobExport={this.state.modal.dobExport}
          csvCallback={this.state.modal.csvCallback}
          text={this.state.modal.text}
          url={this.state.modal.url}
          method={this.state.modal.method}
          lambda_method={this.state.modal.lambda_method}
          buttonText={this.state.modal.buttonText}
          html={this.state.modal.html}
        // dropdownValue={this.state.modal.dropdownValue}
        >
        </Modal>

        {this.state.loading ?

          <Loader /> :

          <Layout
            title={this.props.title}
            loading={this.state.loading}
            language={language}>
            {this.props.display}
          </Layout>

        }

      </ViewContext.Provider>
    );
  }
}
