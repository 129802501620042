export const KIT_ACCOUNT_TYPES = {
    DNA_LITE: "DNA Lite",
    DNA_ONLY: "DNA Only",
    EPI_ONLY: "EPI Only",
    DNA_EPI: "DNA + EPI",
    EPI_SUB: "EPI Subscription",
}
export const USER_ACCOUNT_TYPES = {
    DNA_LITE: "DNA Lite",
    DNA_ONLY: "DNA Only",
    EPI_ONLY: "EPI Only",
    DNA_EPI: "DNA + EPI",
    EPI_ONLY_SUB: "Epi Only Subscription",
    DNA_EPI_SUB: "DNA + EPI Subscription",
    PENDING: "Pending"
}
