import React from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import 'datatableComponents/style.css';
import { ViewContext, Button, LoadingButton, Grid } from 'components/lib';

export class NestedDatatable extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            columns: [],
            rowData: []
        };
    }
    componentDidMount() {
        const { data, ...props } = this.props;
        console.log(data, "in nested");
        const columns = [
            { name: 'Name', selector: 'name' },
            { name: 'Icon', selector: 'icon' },
            { name: 'Action', selector: 'action' }
        ]
        const rowData = data.aspects.map(el => {
            return {
                name: el,
                icon: <i className="fas fa-plus"></i>,
                action: "Add Delete"
            }
        })
        this.setState({ columns, rowData })

    }
    addNewAspect() {

    }
    render() {
        const { data, ...props } = this.props;
        console.log(data, props, "nested")
        createTheme('solarized', {
            text: {
                primary: '#717171',
                secondary: '#268bd2',
            },
            background: {
                default: '#e3f5ff',
            },
            divider: {
                default: '#717171',
            },
            button: {
                default: 'rgb(38, 139, 210)',
                hover: 'rgba(0,0,0,.08)',
                focus: 'rgba(255,255,255,.12)',
                disabled: 'rgba(0,0,0,.12)',
            },
        });
        return (
            <>
                <div className="tableHeadMain">
                    <div className="tsecOne" style={{ paddingTop: "10px", fontWeight: "600", textAlign: "center" }}>{this.props.title}</div>
                    <div className="tsecTwo">
                        <Button className='sUserAdd' text='Add New Aspect' action={this.addNewAspect} />
                    </div>
                </div>
                <DataTable
                    columns={this.state.columns}
                    data={this.state.rowData}
                    // pagination={true} 
                    className="nestedDtableWrap"
                    theme="solarized"
                    // fixedHeader={true}
                    // striped={true}
                    // highlightOnHover={true}
                    noDataComponent=<div>No Aspects present</div>
                    persistTableHead={true}
                />
            </>
        )
    }
}