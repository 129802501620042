import React from 'react';
import { ViewContext, Button, LoadingButton, Grid } from 'components/lib';
import memoize from 'memoize-one';
import { axiosCall } from '../../helpers';
import {
  ADD_KIT_SINGLE, LAMDA_APICALL, KIT_TRACKING_HISTORY,
  KIT_DELETE, KIT_TRACKING_DETAILS, ADD_KIT_TO_QUEUE, EXPORT_PDF, GET_PARTNER, GET_ONE_USER,
  GET_DNA12_MODES, ADD_MODE, ADD_DNA12_QUESTION, EDIT_DNA12_QUESTION
} from '../../apiurl';
import { Filter } from '../../components/Filter/Filter';
import { getKitId } from '../../actions/userAction';
import { connect } from "react-redux";
import DataTable, { createTheme } from 'react-data-table-component';
import { SearchDatatable } from 'datatableComponents/SearchDatatable';
import { NestedDatatable } from 'datatableComponents/NestedTable';
import { DataLoader } from 'datatableComponents/DataLoader';
import 'datatableComponents/style.css';
import moment from 'moment';
import { disableKit } from './service'
import { KIT_ACCOUNT_TYPES } from '../../lib/strings'
import { History } from '../../components/lib';
import { mode } from 'crypto-js';


class dna12Management extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      close: {
        user_id: '',
        state: false
      },
      data: [],
      loading: false,
      reload: false,
      perPage: 100,
      searchVal: '',
      prevData: [],
      blackListedState: null,
      loadmoreBtn: false,
      loadmoreValue: '',
      btnStatus: false,
      btnColorLoad: 'blue',
      btnTextLoad: 'More',
      buttonStyle: { display: 'block' },
      claimOption: [
        { value: null, label: 'All' },
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
      ],
      claimVal: { value: null, label: 'All' },
      validVal: { value: null, label: 'All' },
      claimedState: null,
      user: {},
      expandedRows: [],
      aspectColumns: [
        { name: 'Name', selector: 'col1' },
        { name: 'Icon', selector: 'col2' },
        { name: 'Action', selector: 'col3' }
      ],
      aspectData: [{ col1: 'Cholestrol', col2: 'Data 2' },
      { col1: 'Data 3', col2: 'Data 4' }],
      addQuestionMode: "",
      questionKey: "",
    };

    this.addMode = {
      name: {
        label: 'Name',
        type: 'text',
        required: true,
      },
      icon: {
        label: 'Icon',
        type: 'file',
        required: true
      }
    };

   
    this.deleteUserForm = {
      is_deleted: {
        type: 'hidden',
        value: null
      }
    }

    this.addNewMode = this.addNewMode.bind(this);
    // this.addNewQuestion = this.addNewQuestion.bind(this);
    // this.editQuestion = this.editQuestion.bind(this);
    // this.uploadKitId = this.uploadKitId.bind(this);
    // this.onQuestionInput = this.onQuestionInput.bind(this);
    this.delete = this.delete.bind(this);

  }

  getQuestionForm = () => {
    // this.addQuestionForm = 
    return {
      mode: {
        type: 'hidden',
        value: this.state.addQuestionMode,
      },
      text: {
        label: 'Question Text',
        type: 'text',
        required: true,
      },
      key: {
        type: 'hidden',
        value: 'skin_test_1'
      }
    };
  }
  // onQuestionInput = async (input, value, valid) => {
  //   console.log("Question input", {input, value, valid})
  //   this.setState({ questionKey: value })
  // }
  handleButtonClick = async (e) => {
    let actionName = e.target.name;
    let id = e.target.id;
    let className = e.target.className
    let row_id = e.target.row_id
    console.log("actionName", actionName)

    if (actionName === "toggle") {
      alert("enable")
    }
    else if (actionName == "viewImage") {
      this.context.showModal({
        title: "View Image",
        html: `<div style="display:flex;align-items:center;justify-content:center" }}>
        <img src="${id}"/>
        </div>`,
      })
    } else if (actionName == "questions") {
      const mode_key = e.target.getAttribute('data-mode')
      console.log(mode_key, "questions")
      let questions = JSON.parse(id)
      questions = questions.map(qu => { return { ...qu, edit: false } })
      await this.context.showQuestionsModal({
        title: 'Questions',
        questions: JSON.parse(id),
        mode: mode_key,
        addQuestion: this.addNewQuestion.bind(),
        updateQuestion: this.editQuestion.bind()
      });
    }
    else if (actionName == "questionAdd") {
      // const mode_key = e.target.getAttribute('data-mode')
      this.setState({ addQuestionMode: id }, async () => {
        const formData = this.getQuestionForm()
        console.log(id, this.state.addQuestionMode)
        await this.context.showModal({
          text: "This is a yes/no question. Please add the question text below.",
          title: `Add New Question for ${id} mode`,
          form: formData,
          lamda: ADD_DNA12_QUESTION,
          buttonText: 'Add Question',
          url: LAMDA_APICALL,
          method: 'POST',
        });
      })

    }
  }

  async viewRawData(kitId) {
    console.log(kitId, "modal kit")
    await this.context.showRawDataModal({
      title: 'View Raw Data : ' + kitId,
      kitId: kitId
    });
  }

  async viewTrackingHistory(kitId) {
    console.log(kitId, "modal kit")
    await this.trackingHistory(kitId)

  }
  async viewTrackingDetails(kitId) {
    console.log(kitId, "modal kit")
    await this.getTrackingDetails(kitId)

  }
  async viewgetUserDetails(userId) {
    console.log(userId, "modal edit")
    await this.getUserDetails(userId)
  }

  async componentDidMount() {

    this.setState({ loading: true });
    await this.getAllModes();
    this.setState({ loading: false });
  }

  getAllModes = async () => {
    await axiosCall('post', LAMDA_APICALL, {
      url: GET_DNA12_MODES,
      method: "GET",
    }, (response) => {
      if (response.status) {
        console.log("modes resp", response.data)
        this.setState({
          data: response.data,
          prevData: response.data
        });
      } else {
        this.context.showNotification("Some error occured !...", 'error', false);
      }
    });

  }
  getKitDetails = async () => {
    console.log("getKit details", this.state.blackListedState, this.state.claimedState)
    let data = {
      ...this.state.loadmoreValue && { lastEval: { "id": this.state.loadmoreValue } },
      ...this.state.searchVal && { search: this.state.searchVal.toUpperCase() },
      ...this.state.perPage && { limit: this.state.perPage },
      ...(this.state.blackListedState != null && this.state.blackListedState !== undefined) && { blacklisted: this.state.blackListedState },
      ...(this.state.claimedState !== null && this.state.claimedState !== undefined) && { claimed: this.state.claimedState }
    }
    await this.props.getKitId(data);


  }

  getUserDetails = async (user_id) => {

    await axiosCall('post', LAMDA_APICALL, {
      url: GET_ONE_USER,
      method: "POST",
      data: JSON.stringify({
        "user_id": user_id
      }
      )
    }, async (response) => {
      console.log(response, "kit history response")
      this.context.hideModal(true);
      console.log('this.context', this.context)
      await this.context.showKitDetailsModal({
        title: 'Account Profile : ',
        data: response.data[0],
      });
    });
  }

  checkButtonStaus = async () => {

    if (this.props.kitDetails.xx.LastEvaluatedKey) {
      this.setState({
        loadmoreValue: this.props.kitDetails.xx.LastEvaluatedKey.id,
        btnStatus: false,
        btnColorLoad: 'blue',
        btnTextLoad: 'More',
        buttonStyle: { display: 'block' }
      });

    } else {
      this.setState({
        btnStatus: true,
        btnColorLoad: 'red',
        btnTextLoad: 'End',
        buttonStyle: { display: 'none' }
      });
    }
  };

  addNewMode = async () => {
    await this.context.showModal({
      title: 'Add New DNA12 Mode',
      form: this.addMode,
      lamda: ADD_MODE,
      buttonText: 'Add Mode',
      url: LAMDA_APICALL,
      method: 'POST'
    });
  }

  addNewQuestion = async (question) => {
    alert(question)
  }

  editQuestion = async (key, text, mode) => {
    console.log({ key, text, mode })
    await axiosCall('post', LAMDA_APICALL, {
      url: EDIT_DNA12_QUESTION,
      method: "POST",
      data: JSON.stringify({ key, text, mode }
      )
    }, async (response) => {
      console.log(response, "edit quest response")
      // this.context.hideModal(true);
      alert("Updated successfully")
    });

  }

  delete = async (data, kit_id) => {
    for (let key in this.deleteUserForm)
      this.deleteUserForm[key].value = data[key];
    this.context.showModal({


      lambda_method: 'DELETE',
      title: 'Delete Kit',
      form: this.deleteUserForm,
      buttonText: 'Delete User',
      lamda: `${KIT_DELETE}/${kit_id}`,
      text: 'Are you sure you want to delete kit?',
      url: LAMDA_APICALL,
      method: 'POST'

    }, async () => {
      this.context.showNotification(kit_id + ' was deleted', 'success', true);
      this.setState({
        loadmoreValue: ''
      });
      await this.getKitDetails();
      await this.setState({ data: this.props.kitDetails.xx.Items });
      await this.checkButtonStaus();
    });
  }

  updateKitStatus = async (kit_id, status, date, notify) => {
    let extra = "";
    let new_status = status;
    if (status === "RESULT_READY_DNA") {
      extra = "DNA"
      new_status = "RESULT_READY"
    }
    if (status === "RESULT_READY_EPI") {
      extra = "EPIGENETICS"
      new_status = "RESULT_READY"
    }
    console.log("UpdateKitStatus", kit_id, status, date, notify)
    this.setState({ loading: true })
    await axiosCall('post', LAMDA_APICALL, {
      url: ADD_KIT_TO_QUEUE,
      method: "POST",
      data: [{
        kit_id: kit_id,
        status: new_status,
        date: date,
        notify: notify,
        note: "Updated via SuperAdmin",
        extra: extra
      }]
    }, async (res) => {
      console.log("res", res)
      this.context.showNotification("Sucessfully Updated", 'success', true);
      await this.getKitDetails();
      this.trackingHistory(kit_id)
      this.setState({ data: this.props.kitDetails.xx.Items, loading: false })
      return res
    }).catch((error) => {
      console.log("error ", error)
      this.context.showNotification("Some error occured !...", 'error', false);
      this.setState({ loading: false })
    })
  }

  trackingHistory = async (kit_id) => {


    await axiosCall('post', LAMDA_APICALL, {
      url: KIT_TRACKING_HISTORY,
      method: "POST",
      data: {
        kit_id: kit_id
      }
    }, async (response) => {
      console.log(response, "kit history response")
      // if (response.status) {
      this.context.hideModal(true);
      console.log('this.context', this.context)
      await this.context.showTrackingHistoryModal({
        title: 'Kit Tracking History : ' + kit_id,
        kitId: kit_id,
        data: response.data,
        update: this.updateKitStatus.bind(),
      });

      // } else {
      //   this.context.showNotification("Some error occured !...", 'error', false);
      // }
    });


  }

  getTrackingDetails = async (kit_id) => {
    console.log(kit_id, "eeeeeeeeeeeeeeeeeeee")
    await axiosCall('post', LAMDA_APICALL, {
      url: KIT_TRACKING_DETAILS,
      method: "POST",
      data: {
        kit_id: kit_id
      }
    }, async (response) => {
      console.log(response, "kit tracking detils")
      console.log('this.context', this.context)
      console.log('this.props.kitDetails.xx.Items', this.props.kitDetails.xx.Items)
      this.setState({
        loadmoreValue: ''
      });
      await this.getKitDetails();
      await this.setState({ data: this.props.kitDetails.xx.Items });
      await this.checkButtonStaus();
    });
  }


  claim_filter = async (values) => {
    console.log("Claim_filter", values.value);
    await this.setState({ claimVal: values, loadmoreValue: '', claimedState: values.value });
    await this.getKitDetails();
    if (this.props.kitDetails.xx.Items) {
      this.setState({ data: this.props.kitDetails.xx.Items });
    }
    await this.checkButtonStaus();

    this.setState({
      loadmoreBtn: false
    });
  }

  valid_filter = async (values) => {
    console.log("valid filter")
    await this.setState({ validVal: values, loadmoreValue: '', blackListedState: values.value });
    await this.getKitDetails();
    if (this.props.kitDetails.xx.Items) {
      this.setState({ data: this.props.kitDetails.xx.Items });
    }

    await this.checkButtonStaus();

    this.setState({
      loadmoreBtn: false
    });


    console.log("valid_filter", values);
  }



  render() {

    const columns = memoize(clickHandler => [
      {
        name: 'Mode Name',
        selector: 'name',
      },
      {
        name: 'Description',
        cell: (row) => <div>
          <button className="tblAction fas fa-eye" onClick={clickHandler} id={row.id} name="viewDescription" title="View Description"></button>
          <button className="tblAction fas fa-edit" onClick={clickHandler} name="editDescription" id={row.id} title="Edit Description"></button>
        </div>,
        hide: 'md',
      },
      {
        name: 'Image',
        cell: (row) => <div>
          {/* <img src={row.icon} style={{height:"30px"}}/> */}
          <button className="tblAction fas fa-edit" onClick={clickHandler} name="editImage" id={row.icon} title="Edit Image"></button>
          <button className="tblAction fas fa-eye" onClick={clickHandler} id={row.icon} name="viewImage" title="View Image"></button>
        </div>,
        hide: 'md',
      },
      {
        name: 'Sub-Aspects',
        cell: (row) => <div>Click on the arrow at the left</div>
      },
      {
        name: 'Questions',
        cell: (row) => <div>
          {/* <a onClick={clickHandler} data-mode={row.key} id={JSON.stringify(row.questions)} name="questions">{row.questions.length}  questions</a> */}
          <button className="tblAction fas fa-eye" data-mode={row.key} onClick={clickHandler} name="questions" id={JSON.stringify(row.questions)} title={`${row.questions.length} questions`}></button>
          <button className="tblAction fas fa-plus-circle" onClick={clickHandler} name="questionAdd" id={row.key} title="Add New Question"></button>
        </div>,
      },
      {
        name: 'Status',
        // selector: 'latest_kit_status',
        cell: (row) => <div>{row.status == 'active' ? <button className="tblAction fas fa-toggle-off" onClick={clickHandler} name="toggle" id={row.key} title="Disable"></button> : <button className="tblAction fas fa-toggle-on" onClick={clickHandler} name="toggle" id={row.key} title="Enable"></button>}</div>
      }
      // ,{
      //   name: 'Result Type',
      //   selector: 'result_type',
      //   sortable: true,
      //   hide: 'md',
      // },
      // {
      //   name: 'Tracking Number',
      //   cell: (row) => <div>{row.tracking_details ? (row.tracking_details.TrackingNumber ? <a href ={row.tracking_details.TrackingLink} target="_blank" >{row.tracking_details.TrackingNumber}</a> : 'Not available') : <button className={`tblAction fas ${row.id} fa-redo`} onClick={clickHandler} name="reload" id={row.kit_id} row_id={row.id} title="Reload tracking details"></button>}</div>,
      // },
      // // {
      // //   name: 'Raw Data',
      // //   hide: 'md',
      // //   cell: (row) => <div><a href="">View Data</a></div>,
      // // },
      // {
      //   name: 'Actions',
      //   cell: (row) => <div><button className={`tblAction fas fa-ban ${row.user_id === "NA" ? "" : "disabled"}`} onClick={clickHandler} id={row.id} name="delete" title="Disable kit"></button><button className="tblAction fas fa-eye" onClick={clickHandler} name="view" id={row.kit_id} title="View raw data"></button><button className="tblAction fas fa-history" onClick={clickHandler} name="tracking" id={row.kit_id} title="View tracking history"></button></div>,
      //   ignoreRowClick: true,
      //   allowOverflow: true,
      //   button: true,
      // },
    ]);
    const { loading, data, totalRows } = this.state;

    createTheme('solarized', {
      text: {
        primary: '#717171',
        secondary: '#268bd2',
      },
      background: {
        default: '#ffffff',
      },
      divider: {
        default: '#1595c5',
      },
      button: {
        default: 'rgb(38, 139, 210)',
        hover: 'rgba(0,0,0,.08)',
        focus: 'rgba(255,255,255,.12)',
        disabled: 'rgba(0,0,0,.12)',
      },
    });
    return (
      <div>
        <div className="tableHeadMain">
          {/* <div className="tsecOne">
            <SearchDatatable searchAll={this.searchCall} />
          </div> */}
          <div className="tsecTwo">
            <Button className='sUserAdd' text='Add New Mode' action={this.addNewMode} />
          </div>
        </div>

        <div className="superDataTable kitIdTable">
          <DataTable
            title="DNA12 Modes"
            columns={columns(this.handleButtonClick)}
            data={data}
            progressPending={loading}
            // onSort={this.handleSort}
            sortServer
            progressComponent={<DataLoader />}
            className="superDtableWrap"
            theme="solarized"
            expandableRows
            expandableRowsComponent={<NestedDatatable
              title="Sub-Aspects"
              data={this.state.aspectData} />}
          // columns={this.state.aspectColumns} />}
          // onRowClicked = {this.loadMoreData}
          />
        </div>

      </div>
    );
  }
}
dna12Management.contextType = ViewContext;

const mapStateToProps = state => ({
  kitDetails: state.admin.kitDetails
})
export default connect(mapStateToProps, { getKitId })(dna12Management);
