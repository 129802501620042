/***
*
*   STAT
*   Statistic value with optional icon and -/+ change value.
*
*   PROPS
*   label: string
*   loading: true/false to toggle loading animation (optional)
*   value:  numeric value
*   icon: icon image to use (optional)
*   change: positive/negative number indicating change amount (optional)
*
**********/

import React from 'react'
import { Loader, Card, ViewContext, Grid } from 'components/lib';
import { axiosCall } from '../../helpers';
import { LAMDA_APICALL, GET_ACCOUNT_COUNT } from '../../apiurl';
import './dashboard-box.scss';
import "components/new-global.scss";
import Moment from 'moment'

export class AccountBox extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            count: 0,
            partner: this.props.partner,
            tVal: "ALL",
            accountsOptions: [
                { value: 'ALL', label: 'All' },
                { value: 'DNA_LITE', label: 'DNA LITE' },
                { value: 'DNA_ONLY', label: 'DNA ONLY' },
                { value: 'EPI_ONLY', label: 'EPI ONLY' },
                { value: 'DNA_EPI', label: 'DNA EPI' },
                { value: 'EPI_ONLY_SUB', label: 'EPI ONLY SUB' },
                { value: 'DNA_EPI_SUB', label: 'DNA EPI SUB' },
                { value: 'PENDING', label: 'PENDING' },

            ],
        }
        this.category = "ACCOUNTS"
        this.handleChange = this.handleChange.bind(this);
        this.apiCall = this.apiCall.bind(this);
    }

    componentDidMount() {
        console.log('componentDidMount', this.props.partner)
        try {
            this.setState({
                loading: false,
                partner: this.props.partner
            });
            console.log(this.state)
            this.apiCall(this.state.tVal)
        }
        catch (err) {
            this.context.handleError(err);
        }
    }

    partnerChanged = async (partner) => {
        console.log(partner)
        await this.setState({ partner })
        this.apiCall()
    }

    async apiCall() {
        this.setState({ loading: true });
        await axiosCall('post', LAMDA_APICALL, {
            url: GET_ACCOUNT_COUNT,
            method: "POST",
            data: {
                category: "ACCOUNTS",
                ...this.state.partner !== "ALL" && { partner_id: parseInt(this.state.partner) },
                params: {
                    ...this.state.tVal && { type: this.state.tVal }
                }
            }
        }, (response) => {
            this.setState({ loading: false });
            if (response.status) {
                this.setState({
                    count: response.data.count,
                });
            } else {
                this.context.showNotification("Some Error Occurred, Please try again!", "error", false);
            }
        });
    }

    async handleChange(e) {
        console.log("handle", e.target.value)
        await this.setState({ tVal: e.target.value, count: '' })
        this.apiCall()
    }
    render() {
        if (this.state.loading) {
            return (
                <div className='selectcd card'>
                    <Loader />
                </div>
            );
        }

        return (
            <Card>
                <h1>Accounts</h1>
                <div className="selectBox">
                    <h1>{this.state.count}</h1>

                    <select className="select__type__I" onChange={this.handleChange} value={this.state.tVal || ''}>
                        {this.state.accountsOptions.map(function (item, i) {
                            return <option key={i} value={item.value}>{item.label}</option>
                        })}
                    </select>
                </div>
            </Card>
        );
    }
}

AccountBox.contextType = ViewContext;