/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
*   1. fetches sample data from the server (stats, progress and users)
*   2. renders the ui with stats, charts and table
*
**********/

import React from 'react';
import { connect } from 'react-redux';
import {
  ViewContext, Card,
  AdminCharts, Grid, Selectcard, DashboardBox 
} from "components/lib";
import { addUser, addPartnerId } from '../../actions/userAction';
import { axiosCall } from '../../helpers';
import { GET_PARTNER, GET_GRAPH_DATA } from '../../apiurl';

class adminDashboard extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      partnerValue: "",
      loading: false,
      progress: null,
      stats: null,
      allTypes: 'ALL',
      tVal: 'ALL',
      defaultDate: '7d',
      accountsOptions: [
        { value: 'ALL', label: 'All' },
        { value: 'DNA_LITE', label: 'DNA LITE' },
        { value: 'DNA_ONLY', label: 'DNA ONLY' },
        { value: 'EPI_ONLY', label: 'EPI ONLY' },
        { value: 'DNA_EPI', label: 'DNA EPI' },
        { value: 'EPI_ONLY_SUB', label: 'EPI ONLY SUB' },
        { value: 'DNA_EPI_SUB', label: 'DNA EPI SUB' },
        { value: 'PENDING', label: 'PENDING' },
      ],
      activeOptions: [
        { value: '24h', label: 'Last 24hrs' },
        { value: '7d', label: 'Last 7 days' },
        { value: '30d', label: 'Last 30 days' },
        { value: '90d', label: 'Last 90 days' },
        { value: '180d', label: 'Last 180 days' },
      ],
      graphOptions: [
        { value: '24Hrs', label: 'Last 24hrs' },
        { value: 'Last7Days', label: 'Last 7 days' },
        { value: 'Last30Days', label: 'Last 30 days' },
        { value: 'Last90Days', label: 'Last 90 days' },
        { value: 'Last180Days', label: 'Last 180 days' },
      ]
    }

    this.partnerOptions = [];
    this.graphPartnerOptions = [{ value: "", label: "All" }];
  }

  async componentDidMount() {
    console.log("componentDidMount, 1")
    try {
      let userLocal = JSON.parse(localStorage.getItem('user'));

      await this.props.addUser(userLocal)
      if (userLocal.Partner == null) {
        await this.props.addPartnerId(0);
      } else {
        await this.props.addPartnerId(userLocal.Partner.partnerId);
      }
      this.setState({ loading: true });

      await axiosCall('get', GET_PARTNER, {}, (response) => {
        if (response.status) {
          response.data.forEach(partner => {
            this.partnerOptions.push({
              value: partner.partnerId, label: partner.name
            });
            this.graphPartnerOptions.push({
              value: partner.partnerId, label: partner.name
            });
          });
          this.setState({
            loading: false,
          });
        } else {
          this.context.showNotification("Some Error Occurred, Please try again!", "error", false);
        }
      });
      this.setState({
        partnerValue: this.props.partner,
      });
    }
    catch (err) {
      this.context.handleError(err);
    }
  }




  render() {

    return (
      <>
        <DashboardBox />
        <div className="report-wrp">
          <Card name='User Graph'>
            <AdminCharts
              type='line'
              legend={true}
              data={GET_GRAPH_DATA}
              filter={true}
              partnerId={this.props.partner}
              partnerOptions={this.graphPartnerOptions}
              filterOptions={this.state.graphOptions}
              color={['red', 'violet', 'green', 'purple', 'yellow', 'pink']}
            />
          </Card>
        </div>
      </>

    );
  }
}

adminDashboard.contextType = ViewContext;
const mapStateToProps = state => ({
  user: state.admin.user,
  partner: state.admin.partner,
});
export default connect(mapStateToProps, { addUser, addPartnerId })(adminDashboard);
