/***
*
*   MASTER LAYOUT
*   The master dashboard layout containing the navigation
*   and header (title, secondary nav and signed in user)
*
*   PROPS
*   loading: true/false
*   title: title of the view
*
**********/

import React from 'react';
import ClassNames from 'classnames';
import { AuthContext, Header, HoverNav, Button, History } from 'components/lib';
import './master.scss';
import  AppNav from '../../nav/app/app';

export class MasterLayout extends React.Component {
  constructor(props) {
    super(props)
    this.user = JSON.parse(localStorage.getItem("user"))
    console.log(this.user)
    
  }
  accountSettings(){
    let userLocal = JSON.parse(localStorage.getItem('user'));
    let prefix = ''
    if (userLocal && userLocal.Partner && userLocal.Partner.partnerId) {
      prefix = "/admin"
    }
    let accountLink =`${prefix}/account`
    History.push(accountLink);
  }
  render(){
    document.body.classList.remove('color-bg');
    const menu = this.props.language;
    const items = [{ label: menu('menu-dashboard'), link: '/dashboard' }]
    if(this.user && this.user.UserRoleMapping && this.user.UserRoleMapping[0] &&
       this.user.UserRoleMapping[0].Role && this.user.UserRoleMapping[0].Role.roleKey === "SUPER_ADMIN"){
        items.push({ label: menu('menu-user'), link: '/user-management' })
        items.push({ label: menu('menu-kitId'), link: '/kit-ids' })
        items.push({ label: menu('menu-bulk'), link: '/bulk-upload'})
        items.push({ label: menu('menu-dob'), link: '/dob-export'})
        items.push({ label: menu('menu-dna12'), link: '/dna12'})
        // items.push({ label: menu('menu-subscribed'), link: '/subscribed-users' })
        // items.push({ label: menu('Demo User'), link: '/demo-user' })
    }
    console.log(items);
    console.log("header rols",this.user.UserRoleMapping[0].Role.roleKey);
        const cssClass = ClassNames({

      'app': true,
      'master': true,
      'with-sidebar': true,
      'with-bottom-nav': true,
      'hide': this.props.loading

    })

    return (

      <>
      <AppNav
        type='fixed'
        items={items}
      />

      <main className={ cssClass }>

        <Header title={ this.props.title }>
          <HoverNav label={ this.context.user.name } align='right'>
            <button text='Profile' onClick={ this.accountSettings }>Profile</button>
            <Button text='Signout' action={ this.context.signout }/>
          </HoverNav>
        </Header>

        { <this.props.children/> }

      </main>
      </>

    );
  }
}

MasterLayout.contextType = AuthContext;
