/***
*
*   MODAL
*   To display an overlay modal anywhere in your application call
*   this.context.showQuestionsModal({ }) with an object containing the following props
*
*   PROPS
*   title: (optional)
*   questions: Questions array with question_text, createdAt
*   addQuestion: Method to add question
*   editQuestion: Method to edit question
*
**********/

import React from 'react';
import { Form, ViewContext, Icon } from 'components/lib';

import './modal.scss';
import { Loader, Table, Card, Grid, Child } from '../lib';
import moment from 'moment';
import { SINGLESTATUS } from '../../constants/Constants';

const header = [
    { name: 'question', title: 'Question', sort: true },
    { name: 'date', title: 'Added Date', sort: true },
    { name: 'action', title: 'Action', sort: false },
]

export class ViewQuestionsModal extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            questionList: this.props.questions,
            questionEdit: [],
            loader: false,
            update_status: false,
            question: "",
            select: null,
            checkbox: false,
            checked: true,
            edit: false
        }
    }
    clearState() {
        this.setState({
            questionList: [],
            loader: false,
            update_status: false,
            question: "",
            select: null,
            checkbox: false,
            checked: true
        });
        console.log(this.state)
    }

    // componentDidMount() {
    //     let questionEdit = new Array(this.props.questions.length).fill(false)
    //     // questionEdit.fill(false, 0, this.props.questions.length-1)
    //     this.setState({ questionList: this.props.questions, questionEdit });
    // }
    componentDidUpdate(prevProps) {
        if (prevProps.questions !== this.props.questions &&
            this.props.questions && this.props.questions.length > 0) {
            let questionEdit = new Array(this.props.questions.length).fill(false)

            this.setState({ questionList: this.props.questions, questionEdit });
        }
    }

    handleclick = () => {
        console.log("Handle click")
        this.setState({ update_status: !this.state.update_status })
    }

    onChangeQuestion = (event) => {
        const idx = event.target.id
        const { questionList } = this.state
        questionList[idx].question_text = event.target.value
        this.setState({ questionList })
    }

    onEditQuestion = async (event) => {

        console.log(event.target.value, event.target.id)
        const idx = event.target.id
        const { questionEdit, questionList } = this.state;
        const isEdit = questionEdit[idx]
        if (isEdit) {
            await this.props.updateQuestion(
                questionList[idx].question_key,
                questionList[idx].question_text,
                this.props.mode
            )
            questionEdit[idx] = false
            this.setState({ questionEdit })
        } else {
            questionEdit[idx] = true
            this.setState({ questionEdit })
        }
    }
    onCheckBox = (event) => {
        console.log("checkbox", event)
        this.setState({ checked: event.target.checked })
    }

    handleAdd = () => {
        const { question } = this.state
        console.log("question", question)
        this.props.addQuestion(question)
    }

    // updateSingleQuestion = () => {
    //     this.props.editQuestion()
    // }
    close = async (context) => {
        context.hideQuestionsModal(true)
        this.clearState()
    }

    render() {
        const { questionList, questionEdit } = this.state
        return (
            <ViewContext.Consumer>
                {(context) => (
                    <div className={"modal " + context.questionModal.visible} style={{ overflow: 'scroll' }}>
                        <div className='modal-content card'>
                            {this.props.title &&
                                <header>
                                    <h1>{this.props.title} </h1>
                                    <div onClick={event => this.close(context)} className="closeBtnModal"><Icon color="dark" image="x" /></div>
                                </header>
                            }
                            {questionList &&
                                <div className="table-container">
                                    {/* <div className="container-head">
                                        <div className="ipBox">
                                            <div className='question-title'>Add New Question</div>
                                            <input type="text" placeholder="Enter question text" value={this.state.question} onChange={(e) => { this.setState({ question: e.target.value }) }} />
                                            <button
                                                className={this.state.update_status ? "hidden" : "btn btn-success"}
                                                onClick={this.handleAdd}>
                                                Add
                                            </button>
                                        </div>
                                    </div> */}
                                    <div className="filter-dots" style={{ fontSize: "13px" }}>

                                        <Grid cols='1'>
                                            <div style={{ overflowY: 'auto', height: "400px" }}>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Question</th>
                                                            {/* <th className="text-center">Created Date</th> */}
                                                            <th className="text-center">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            questionList.length > 0 ?
                                                                questionList.map((items, index) => (
                                                                    <tr key={items.question_key}>
                                                                        {/* <td>{items.question_text}</td> */}
                                                                        <td><input
                                                                            type='text'
                                                                            id={index}
                                                                            key={items.question_key}
                                                                            value={items.question_text}
                                                                            disabled={!questionEdit[index]}
                                                                            onChange={this.onChangeQuestion}
                                                                        /></td>
                                                                        {/* <td className="text-center">
                                                                            {moment(items.createdAt).format("DD-MM-YYYY")}
                                                                        </td> */}
                                                                        <td className="text-center">
                                                                            {/* <button className="tblAction fas fa-edit" onClick={this.onEditQuestion} name="editQuestion" id={index} key={items.question_key} title="Edit Question"></button> */}
                                                                            <button
                                                                                className={questionEdit[index] ? "tblAction fas fa-check-circle" : "tblAction fas fa-edit"}
                                                                                onClick={this.onEditQuestion}
                                                                                name="editQuestion"
                                                                                id={index} key={"edit_" + items.question_key}
                                                                                title="Edit Question"
                                                                            >
                                                                            </button>
                                                                            <button
                                                                                className="tblAction fas fa-trash"
                                                                                onClick={this.onDeleteQuestion}
                                                                                name="deleteQuestion"
                                                                                id={index} key={"delete_" + items.question_key}
                                                                                title="Delete Question"
                                                                            >
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                                :
                                                                null
                                                        }

                                                    </tbody>
                                                </table>
                                                {/* <Table
                                                    data={{
                                                        header: header,
                                                        body: questionList.map(qu => ({
                                                            question: qu.question_text,
                                                            date: moment(qu.createdAt).format("DD-MM-YYYY"),
                                                            action: <button className="tblAction fas fa-edit" onClick={this.props.updateSingleQuestion} name="editQuestion" id={qu.question_key} title="Edit Question"></button>
                                                        }))
                                                    }}
                                                    loading={this.state.loader}
                                                    badge={{ col: 'plan', color: 'blue' }}>
                                                </Table> */}
                                            </div>
                                        </Grid>

                                    </div>

                                </div>
                            }
                        </div>


                    </div>

                )}
            </ViewContext.Consumer>
        );
    }
}
ViewQuestionsModal.contextType = ViewContext;
