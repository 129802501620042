/***
*
*   MODAL
*   To display an overlay modal anywhere in your application call
*   this.context.showModal({ }) with an object containing the following props
*
*   PROPS
*   title: (optional)
*   text: message to the user (optional)
*   form: a form object (optional: see form docs for more information)
*   url: destination to send the form
*   method: HTTP post type
*   buttonText – text for the confirm button
*
**********/

import React from 'react';
import { Form, ViewContext, Icon } from 'components/lib';
import CSVReader from "react-csv-reader";

import './modal.scss';

export class Modal extends React.Component {
  // To close escape and outside modal
  // close(event, context){

  //   if (event.target === event.currentTarget)
  //     context.hideModal(true)

  // }
  constructor(props) {

    super(props);
    this.state = {
      kit: [],
      invalidKits: [],
      email: "",
      full: false,
      dobFull: false,
      error: false,
      // partner: 1,
      show: false,
      btnDisable: false,
      submitText: 'Submit'
    }
  }
  handleExport = async (data, fileName) => {
    console.log(data, fileName);
    let kits = [];
    for (let kids of data) {
      if (!kids.kit_id) break
      console.log("kids", kids)
      kits.push(
        {
          kit_id: kids.kit_id.trim(),
          // first_name: kids.first_name,
          // last_name: kids.last_name,
          // dob: kids.dob,
          // language: kids.language,
          type: kids.type.trim(),
        }
      );


    }
    this.setState({ kit: kits });
  }


  handleForce = async (data, fileName) => {
    console.log(data, fileName);
    let kits = [];
    for (let kids of data) {
      if (!kids.kit_id) break

      kits.push(
        {
          kit_id: kids.kit_id,
          account_type: kids.account_type
        }
      );


    }
    this.setState({ kit: kits });
  }

  handleKitServiceUpload = async (data, fileName) => {
    console.log(data, fileName);
    let kits = [], invalidKits = [];
    const ALLOWED_KIT_SERVICE_TYPES = ['DNA', 'EPI', 'DNA_EPI', 'EPI_FULL', 'DNA_EPI_FULL', 'GSA_PGX', 'GDA_PGX', 'GSA_GDA_PGX']
    for (let kids of data) {
      if (!kids.kit_id) break

      if (ALLOWED_KIT_SERVICE_TYPES.includes(kids.service))
        kits.push({
          kit_id: kids.kit_id,
          service_type: kids.service
        });
      else
        invalidKits.push(kids.kit_id)
    }
    this.setState({ kit: kits });
  }

  handleBulkForce = async (data, fileName) => {
    console.log("Bulk model check ", data, fileName);
    let kits = [];
    if (data.length <= 50) {
      // Remove precious csv presnt
      if (this.state.kit.length > 0) {
        console.log("If already present in front")
        this.setState({ kit: [] })
      }
      data.map((kids, index) => {
        console.log(kids, index)
        let status = kids.status && kids.status.replace(/^[ ]+|[ ]+$/g, '')
        let date = kids.date && kids.date.replace(/^[ ]+|[ ]+$/g, '')
        kits.push(
          {
            "kit_id": kids.kit_id ? kids.kit_id : "Invalid kit id",
            "status": status ? status : "Invalid kit status",
            "date": date ? date : "Invalid date",
            "extra": kids.extra
          }
        );
      });
    } else if (typeof (data) == "string") {
      this.setState({ error: true })
    } else {
      this.setState({ full: true })
    }
    this.setState({ kit: kits });
  }

  handleDobCsvUpload = async (data, fileName) => {
    console.log("Bulk model check ", data, fileName);
    let kits = [];
    if (data.length <= 100) {
      // Remove previous csv presnt
      if (this.state.kit.length > 0) {
        console.log("If already present in front")
        this.setState({ kit: [] })
      }
      data.map((kids, index) => {
        console.log(kids, index)
        kits.push(
          {
            "kit_id": kids.kit_id
          }
        );

      });
    } else if (typeof (data) == "string") {
      this.setState({ error: true })
    } else {
      this.setState({ dobFull: true })
    }
    this.setState({ kit: kits });
  }

  submitCsv = async (dataArray) => {
    if (this.state.full) {
      await this.props.csvCallback({ error: "Please enter only 50 rows!...", report: "limit" });
    } else if (this.state.dobFull) {
      await this.props.csvCallback({ error: "Please enter only 100 rows!...", report: "limit" });
    } else if (this.state.error) {
      await this.props.csvCallback({ error: "Something went wrong!...", report: "error" });
    } else {
      this.setState({ btnDisable: true, submitText: "Uploading..." })
      await this.props.csvCallback(dataArray);
      this.setState({ btnDisable: false, submitText: "Submit" })
    }

  }

  exportsubmitCsv = async (dataArray) => {
    if (this.state.email !== "") {
      if (this.state.full) {
        await this.props.csvCallback({ error: "Please enter only 50 rows!...", report: "limit" });
      } else if (this.state.error) {
        await this.props.csvCallback({ error: "Something went wrong!...", report: "error" });
      } else {
        await this.props.csvCallback(dataArray, this.state.email);
      }
    } else {
      await this.props.csvCallback({ error: "Please enter valid email", report: "error" })
    }
  }

  // partnerSelect = (e) => {
  //   this.setState({partner: e.target.value})
  // }
  // toggleNote = () => {
  //   this.setState({show: !this.state.show})
  // }
  // kits.push(
  //   kids.kit_id,
  //   kids.status,
  //   kids.date
  // );

  render() {

    const papaparseOptions = {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
    };

    return (
      <ViewContext.Consumer>
        {(context) => (
          // To close escape and outside modal
          // <div className={ "modal " + context.modal.visible }
          // onClick={(event) => this.close(event, context) }>
          <div className={"modal " + context.modal.visible}>

            <div className='modal-content card'>

              {this.props.title &&
                <header>
                  <h1>{this.props.title} </h1>
                  <div onClick={event => context.hideModal(true)} className="closeBtnModal"><Icon color="dark" image="x" /></div>
                </header>
              }

              {this.props.text &&
                <p>{this.props.text}</p>
              }
              {this.props.uploadCSV &&
                <div>
                  <CSVReader
                    cssClass="react-csv-input"
                    label="Select a CSV file"
                    onFileLoaded={this.handleForce}
                    parserOptions={papaparseOptions}
                  />
                  <div className="smDownLink">Sample file - <a download="sample.csv" target="_blank" href="sampleFile/kitsample.csv">download</a></div>
                  <div className="buttonsCsv">
                    <button className="btn blue" onClick={event => this.submitCsv(this.state.kit)}>Submit</button>
                    <button className="btn btn-outline" onClick={event => context.hideModal(true)}>Cancel</button>
                  </div>
                </div>
              }
              {this.props.exportCSV &&
                <div>
                  {/* <div style={{display:'flex'}}> */}
                  <CSVReader
                    cssClass="react-csv-input"
                    label="Select a CSV file"
                    onFileLoaded={this.handleExport}
                    parserOptions={papaparseOptions}
                  />
                  {/* <div className='partnerDropdown'> */}
                  {/* <span style={{marginTop: '12px', marginRight: '5px'}}>Partner:</span>
                    <select className='select__type__II' onChange={this.partnerSelect} value={this.state.partner}>
                      {this.props.dropdownValue && this.props.dropdownValue.map(function (item, i) {
                        return <option key={i} value={item.value} >{item.label}</option>
                      })}
                    </select>
                  </div> */}
                  <div className="smDownLink">Sample file - <a download="sample.csv" target="_blank" href="sampleFile/test_dummy_pdf.csv">download</a></div>
                  {/* <div className="modalNote">
                    <a href='#' onClick={this.toggleNote}>
                      {this.state.show ? '<<< Hide CSV Instructions' : 'Show CSV Instructions >>>'}</a>
                    {this.state.show &&
                      <div>
                        <ul>
                          <li>Please enter DOB in MM-DD-YYYY format.</li>
                          <li>Language Abbreviations
                            <ol>
                              <li>en - English</li>
                              <li>de - German</li>
                              <li>it - Italian</li>
                              <li>lt - Lithuanian</li>
                              <li>nl - Dutch</li>
                              <li>pl - Polish</li>
                              <li>zh - Chinese</li>
                            </ol>
                          </li>
                          <li>The CSV should contain users' details belonging to the selected partner</li>
                        </ul>
                      </div>
                    }
                  </div> */}
                  {/* <div className="modalNote">*Please enter DOB in MM-DD-YYYY format.</div> */}
                  <div className="smDownNote">Note: *10 list per csv can only be uploaded at a time.</div>
                  <input className='emailInput' type="email" placeholder="Enter Email" onChange={(e) => this.setState({ email: e.target.value })} />
                  <div className="buttonsCsv">
                    <button className="btn blue" onClick={event => this.exportsubmitCsv(this.state.kit)}>Submit</button>
                    <button className="btn btn-outline" onClick={event => context.hideModal(true)}>Cancel</button>
                  </div>
                </div>
              }
              {this.props.uploadBulk &&
                <div>
                  <CSVReader
                    cssClass="react-csv-input"
                    label="Select a CSV file"
                    onFileLoaded={this.handleBulkForce}
                    parserOptions={papaparseOptions}
                  />
                  <div className="smDownLink">Sample file - <a download="sample.csv" target="_blank" href="sampleFile/kitbulksample.csv">download</a></div>
                  <div className="smDownNote">Note: *50 list per csv can only be uploaded at a time.</div>
                  <div className="buttonsCsv">
                    <button className="btn blue" onClick={event => this.submitCsv(this.state.kit)}>Submit</button>
                    <button className="btn btn-outline" onClick={event => context.hideModal(true)}>Cancel</button>
                  </div>
                </div>
              }
              {this.props.uploadKitService &&
                <div>
                  <CSVReader
                    cssClass="react-csv-input"
                    label="Select a CSV file"
                    onFileLoaded={this.handleKitServiceUpload}
                    parserOptions={papaparseOptions}
                  />
                  <div className="smDownLink">Sample file - <a download="sample.csv" target="_blank" href="/sampleFile/kit-service-sample.csv">download</a></div>
                  <div className="buttonsCsv">
                    <button className="btn blue" disabled={this.state.btnDisable} onClick={event => this.submitCsv(this.state.kit)}>{this.state.submitText}</button>
                    <button className="btn btn-outline" onClick={event => context.hideModal(true)}>Cancel</button>
                  </div>
                </div>
              }
              {this.props.dobExport &&
                <div>
                  <CSVReader
                    cssClass="react-csv-input"
                    label="Upload a CSV file with Kit IDs"
                    onFileLoaded={this.handleDobCsvUpload}
                    parserOptions={papaparseOptions}
                  />
                  <div className="smDownLink">Sample file - <a download="dob-export-sample.csv" target="_blank" href="sampleFile/dobsample.csv">Download</a></div>
                  {/* <div className="smDownNote">Note: *50 list per csv can only be uploaded at a time.</div> */}
                  <div className="buttonsCsv">
                    <button className="btn blue" onClick={event => this.submitCsv(this.state.kit)}>Submit</button>
                    <button className="btn btn-outline" onClick={event => context.hideModal(true)}>Cancel</button>
                  </div>
                </div>
              }
              {this.props.form &&
                <Form
                  method={this.props.method}
                  lambda_method={this.props.lambda_method}
                  url={this.props.url}
                  data={this.props.form}
                  lamda={this.props.lamda}
                  buttonText={this.props.buttonText}
                  cancel={event => context.hideModal(true)}
                />
              }
              {
                this.props.html &&
                <div dangerouslySetInnerHTML={{ __html: this.props.html }}></div>
              }
            </div>
          </div>

        )}
      </ViewContext.Consumer>
    );
  }
}
