/***
*
*   APP LAYOUT
*   Main application layout containing the navigation
*   and header (title, secondary nav and signed in user)
*
*   PROPS
*   loading: true/false
*   title: title of the view
*
**********/

import React from 'react';

import { AuthContext, Header, HoverNav, Button } from 'components/lib';
import ClassNames from 'classnames';
import '../layout.scss';
import './app.scss';
import {getMenu} from '../../../helpers'
import  AppNav from '../../nav/app/app';

export class AppLayout extends React.Component {
  
  render(){

    // remove the background color applied from the homepage
    document.body.classList.remove('color-bg');
   let menu = getMenu()
   console.log("mmmmmmmmmmmmmmmm",menu)
   let demo_partner = [1,5,7,6,11,15,8,13,16,14,20,18,19,4,21,22,23,26]
   let partnerId = this.props.partner || parseInt(localStorage.getItem('partner_idd'))|| parseInt(localStorage.getItem('partner_id'))
   
    if (!demo_partner.includes(partnerId)) {
      menu = menu.map(m => {
        if (m.label == 'Account Management') {
          console.log("Removing demo menu")
          m.sub.pop()
        }
        return m
      })
      // if (menu[2]) {
      //   menu[2].sub.pop()
      // } else if (menu[1]) {
      //   menu[1].sub.pop()
      // }
      console.log("partnerrrrrrrrr", partnerId)
    }
   console.log("menumenumenumenu",menu);
    const cssClass = ClassNames({

      'app': true,
      'with-sidebar': true,
      'with-bottom-nav': true,
      'hide': this.props.loading

    });
    let userLocal = JSON.parse(localStorage.getItem('user'));
    let prefix = ''
    if (userLocal && userLocal.Partner && userLocal.Partner.partnerId) {
      prefix = "/admin"
    }
    let accountLink =`${prefix}/account`
    return (

      <>
      <AppNav
        type='fixed'
        items={menu}
      />

      <main className={ cssClass }>

        <Header title={ this.props.title }>
          <HoverNav label={ this.context.user.name } align='right'>
            <Button text='Account Settings' action={ event => window.location = accountLink }/>
            <Button text='Signout' action={ this.context.signout }/>
          </HoverNav>
        </Header>

        { <this.props.children/> }

      </main>
      </>

    );
  }
}

AppLayout.contextType = AuthContext;
