/***
*
*   SIGN IN
*   Sign in form for all account types (including master).
*
**********/

import React from 'react';
import { AuthContext, ViewContext, Form, Card, Logo, Seperator, Icon } from 'components/lib';
import { Select } from '../../components/form/select/select'
import { AutoFillEmailInput } from "../../components/form/input/autofillEmail";
import { PasswordInput } from "../../components/form/input/password";
import { axiosCall, getMenu } from '../../helpers';
import { GET_PARTNER_VIA_EMAIL, LOGIN, LAMDA_APICALL, GET_APP_CONFIG } from '../../apiurl';
import { Link, LoadingButton, History } from 'components/lib';
import ClassNames from 'classnames';
import CryptoJS from 'crypto-js'

export class SigninPwd extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      email: {
        valid: true,
        loading: false,
        value: this.getDetailsFromParam(3),
      },
      password: {
        value: '',
        visibility: true,
        valid: undefined
      },
      button: {
        disabled: true,
        text: "Sign In",
        loading: false,
      },
      cred: null
    }
    console.log(this.props, "prpos")
  }

  componentDidMount() {
    let json = this.decrypt()
    console.log(json, "--------------")
    this.setState({
      email: {
        valid: true,
        loading: false,
        value: json.email,
      },
      cred: json
    })
  }
  _onChange = async (input, value, valid) => {
  }


  _onChange1 = async (input, value, valid) => {
    var valid = (value === '') ? false : true
    await this.setState({
      password: {
        valid: valid,
        value: value,
        visibility: true
      },
    });

  }
  submit = async (e) => {

    var disabled = !this.state.password.valid
    console.log(disabled)
    e.preventDefault();

    if (disabled) return
    var data = {
      email: this.state.cred.email,
      partner_id: this.state.cred.partner ? this.state.cred.partner : null,
      password: this.state.password.value
    }
    console.log(data, "promose")
    await axiosCall('post', LOGIN, data, async (response) => {

      if (response.status) {
        this.context.showNotification(response.message, 'success', true)
        await this.signin(response)
      } else {
        this.context.handleError(response.message);
      }
      this.setState({
        partnerLoad: false,
      });
    });
  }

  getDetailsFromParam = (segement) => {
    let url = window.location.href;
    const segements = url.split("/")
    let seg = segement + 2
    return segements[seg]
  }

  decrypt = () => {
    try {
      var ciphertext = decodeURIComponent(this.getDetailsFromParam(3))
      var bytes = CryptoJS.AES.decrypt(ciphertext.toString(), 'vfs^*%rt');
      let json = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      if (json.email === undefined && json.partner === undefined) History.goBack()
      return json
    } catch (e) {
      History.goBack()
    }

  }

  async signin(res) {
    if (res.status) {
      localStorage.setItem('user', JSON.stringify(res.data));
      await this.getAppManagementPartners()

      if (res.data.Partner) {
        const menu = getMenu()
        let pName = res.data.Partner.name;
        // let pName = pNameRef.Partner.name

        let uri = pName ? pName.replace(' ', '_').toLowerCase() : null;

        var link = '/adminDashboard/' + uri;
        if (menu && menu[0]) {
          if (menu[0].link) {
            let Menulink = menu[0].link;

            if (Menulink === '/adminDashboard/') {
              link = '/adminDashboard/' + uri;
            } else {
              link = Menulink;
            }
          } else if (menu[0].sub && menu[0].sub[0] && menu[0].sub[0]['link']) {
            link = menu[0].sub[0]['link'];
          }
        }
        window.location = link
      } else if (res.data.UserRoleMapping[0].Role.category === 'master') {
        window.location = '/dashboard';
      }
      else {
        window.location = '/user-management'

      }
    }
  }
  goBacktoLogin = () => {
    console.log("goBacktoLogin")
    History.push("/signin/identifier")
  }

  async getAppManagementPartners() {
    const user = JSON.parse(localStorage.getItem("user"))

    const roleKey = user && user.UserRoleMapping && user.UserRoleMapping[0] &&
      user.UserRoleMapping[0].Role && user.UserRoleMapping[0].Role.roleKey ? user.UserRoleMapping[0].Role.roleKey : null
    console.log('roleKey.', roleKey);

    if (roleKey == "SUPER_ADMIN") {
      await axiosCall('post', LAMDA_APICALL, {
        url: GET_APP_CONFIG,
        method: "GET",
      }, (res) => {
        console.log("res app mange partners", res)
        localStorage.setItem('app_management_partners', JSON.stringify(res.data));
        return res
      }).catch((error) => {
        console.log("error ", error)
      })
    }
  }

  render() {
    const cssClass = ClassNames({
      'loading': this.state.loading,
      [this.props.className]: this.props.className
    })
    var disabled = !this.state.password.valid
    // console.log(this.state.button.disabled, !this.state.email.valid, !this.state.password.valid)

    console.log(this.state.email, this.state.password)
    return (

      <>
        <Logo />
        <Card>
          <AuthContext.Consumer>
            {(context) => {

              return (
                <form
                  method="POST"
                  onSubmit={this.submit}
                  className={cssClass}
                  noValidate>

                  <div className="login-email-title">Welcome</div>
                  <div className="login-email"  >
                    <span>
                      <div className="login-email-close" onClick={this.goBacktoLogin}>
                        <Icon image='arrow-left-circle'
                          color='#ff1002'
                          size={20} />
                      </div>
                      {this.state.email.value}</span></div>

                  <div style={{ display: 'none' }} >
                    <AutoFillEmailInput
                      key={"email"}
                      type='email'
                      form="signin"
                      label={"EMAIL"}
                      className={''}
                      name={'email'}
                      visibility={false}
                      required={true}
                      onChange={this._onChange}
                      valid={this.state.email.valid}
                      autoComplete={'off'}
                      value={this.state.email.value}
                    />
                  </div>
                  <div style={this.state.password.visibility ? {} : { display: 'none' }}>
                    <PasswordInput
                      key={"password"}
                      form="signin"
                      label={"password"}
                      className={''}
                      name={'Password'}
                      required={true}
                      onChange={this._onChange1}
                      valid={this.state.password.valid}
                      value={this.state.password.value}
                      tabIndex="1"
                      autoFocus={true}
                    ></PasswordInput>
                  </div>
                  <Link
                    url='/forgotpassword'
                    text='Forgot your password?'
                  />
                  <LoadingButton
                    loading={this.state.button.loading}
                    text={this.state.button.text}
                    disabled={disabled}
                    className={disabled ? 'grey btn-disabled' : 'blue'}
                  />

                </form>

              )
            }}
          </AuthContext.Consumer>
        </Card>
      </>

    );
  }
}

SigninPwd.contextType = ViewContext;
